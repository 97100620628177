
  import { computed, defineComponent } from 'vue';
  import WeInfoRow from '@/components/WePrint/WeInfoRow.vue';
  import WePrint from '@/components/WePrint/index.vue';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  import dayjs from 'dayjs';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { useJump } from '@/hooks/useJump';
  import { formatNumber } from '@/utils/number';
  import { increase_pay_print } from '@/api/erp-pay';
  export default defineComponent({
    name: 'erpPaysPrint',
    components: {
      WeInfoRow,
      WePrint,
    },
    setup() {
      const info = computed<Recordable>(() => {
        return appStore.printInfo;
      });
      const { jumpToPay } = useJump();
      const { user } = useUser();
      const { userMap, model } = useAppInfo();

      return {
        info,
        jumpToPay,
        user,
        userMap,
        model,
      };
    },
    data() {
      return {
        showPrintPage: true,
      };
    },
    computed: {
      bill(): Recordable {
        return this.info.bill;
      },
      pay(): Recordable {
        return this.info.pay;
      },
      linkBill(): Recordable {
        return this.info.linkBill;
      },
      isDirect(): boolean {
        return this.pay && this.pay.category === this.model.Pay.CATEGORY.DIRECT;
      },
      isOversea(): boolean {
        return this.pay.is_china === false;
      },
      bankInfo(): string {
        const infoList: string[] = [];
        infoList.push(`Beneficiary: ${this.pay.company.name}`);
        infoList.push(`Bank: ${this.pay.bank.bf_bank}`);
        infoList.push(`Account Number: ${this.pay.bank.bf_account_number}`);
        if (this.isOversea) {
          infoList.push(`Swift Code: ${this.pay.bank.bf_swift_code}`);
          infoList.push(`Beneficiary Address: ${this.pay.company.invoice_address}`);
          infoList.push(`Bank Address: ${this.pay.bank.bf_bank_address}`);
        }
        return infoList.join('        ');
      },
    },
    methods: {
      formatNumber,
      isEmptyData,
      getNow() {
        return dayjs().format('YYYY-MM-DD HH:mm');
      },
      payPrintClicked() {
        console.log('printed');
        increase_pay_print({ id: this.info.pay.id }).then((res) => {
          appStore.SET_PRINT_INFO({
            pay: res.pay,
            linkBill: this.info.linkBill,
          });
        });
      },
    },
    watch: {
      showPrintPage(to) {
        const vm = this;
        if (!to) {
          const pay = JSON.parse(JSON.stringify(vm.pay));
          appStore.CLEAR_INFO();
          vm.jumpToPay(pay);
        }
      },
    },
    created() {
      if (!this.info) this.$router.push({ name: 'WB' });
    },
  });
